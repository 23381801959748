<template>
    <div class="relative flex">
        <el-input ref="searchInput" class="w-64" placeholder="Snabbsökning" @keyup.native="inputChange" @clear="inputChange" v-model="query" clearable />
        <!-- <el-popover placement="top-start" title="Du kan söka efter" width="200" trigger="hover">
            <p @click="prependSearchWith('kund')" class="my-1 py-2 px-4 bg-blue-50 hover:bg-blue-200 transition-colors rounded-lg cursor-pointer">
                Kund
            </p>
            <p @click="prependSearchWith('fnr')" class="my-1 py-2 px-4 bg-blue-50 hover:bg-blue-200 transition-colors rounded-lg cursor-pointer">
                Faktura nr
            </p>
            <p @click="prependSearchWith('belopp')" class="my-1 py-2 px-4 bg-blue-50 hover:bg-blue-200 transition-colors rounded-lg cursor-pointer">
                Belopp
            </p>
            <p @click="prependSearchWith('betalt')" class="my-1 py-2 px-4 bg-blue-50 hover:bg-blue-200 transition-colors rounded-lg cursor-pointer">
                Betalt
            </p>
            <p @click="prependSearchWith('ocr')" class="my-1 py-2 px-4 bg-blue-50 hover:bg-blue-200 transition-colors rounded-lg cursor-pointer">
                OCR
            </p>
            <div class="absolute right-1 top-0.5" slot="reference">
                <i class="far fa-filter-list text-lg" />
            </div>
        </el-popover> -->
    </div>
</template>
<script>
import queryString from "query-string";

export default {
    data() {
        return {
            query: "",
            timer: null,
        };
    },

    created() {
        this.assignInitValues();
    },

    methods: {
        assignInitValues() {
            const { search } = queryString.parse(location.search);
            this.query = search;
        },

        inputChange() {
            clearInterval(this.timer);
            this.timer = setTimeout(() => {
                this.updateUrl();
                this.$emit("change");
            }, 700);
        },

        updateUrl() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.pageNumber = 1;
            query.search = this.query;
            this.$router.replace({ query }).catch(() => {});
        },

        // prependSearchWith(param) {
        //     this.query = param + ":";
        //     this.$refs.searchInput.focus();
        // },
    },
};
</script>
